import axios from 'axios';

//获取个人设置信息
export async function GetUserSettingInfoByID(id){
  var res = await axios.get('User/v1/UserInfoByID', { params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取用户账号
export async function GetUserAccountInfoByID(id){
  var res = await axios.get('User/v1/UserAccountByID', { params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取用户角色
export async function GetUserCharacterByID(id){
  var res = await axios.get('User/v1/UserCharacterByID', { params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取老师（实际上获取的是所有员工）
export async function GetTeachers(){
  var res = await axios.get('User/v1/Teachers')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取的是所有员工
export async function GetAllUser(){
  var res = await axios.get('User/v1/Teachers')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}


//员工列表
export async function GetEmployeeList(){
  var res = await axios.get('User/v1/UserInfos',{ params: { name:"", employeetag:1, state:0, start:1, count:1000}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}


//获取员工列表
export async function GetAllEmployeeList(){
  var res = await axios.get('User/v1/AllUserInfos')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//获取员工列表
export async function GetUsersList(query){
  var res = await axios.get('User/v1/UserInfos',{ params: query})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//重置用户密码
export async function PutAccountPassword(resetPassword){
  var res = await axios.put('User/v1/AccountPassword', resetPassword)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//修改用户身份状态
export async function PutModifyUserState(modifyuserstate){
  var res = await axios.put('User/v1/ModifyUserInfoByUserID', modifyuserstate)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//删除提交信息
export async function DeleteUserAccount(userID){
  var res = await axios.delete('User/v1/UserAccount', {params: {userID : userID}})
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//分配用户角色
export async function PutAccountCharacter(AccountCharacter){
  var res = await axios.put('User/v1/AccountCharacter', AccountCharacter)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}


//提交新建用户
export async function PostAccountUser(userdata){
  var res = await axios.post('User/v1/AccountUser', userdata)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//修改用户信息
export async function PutModifyUserData(modifyuserdata){
  var res = await axios.put('User/v1/UserSelfInfo', modifyuserdata)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}

//获取登录的账号信息
export async function GetLoginUserInfo(){
  var res = await axios.get('User/v1/UserInfo')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}