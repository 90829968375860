<template>
    <el-dialog :append-to-body="true" title="编辑学生信息" :visible.sync="EditStudentInfoDialogVisible" 
    :close-on-click-modal="false" width="40%" @close="CloseDialog">
        <el-tabs tab-position="left" class="tab_div" v-model="ActiveName">
            <el-tab-pane label="修改头像">
                <div class="form_div">
                    <el-row>
                        <el-col :span="7">
                            <el-upload
                                ref="UploadImageref"
                                :action="StudentAvatarUploadUrl"
                                :show-file-list="false"
                                :on-success="HandleAvatarSuccess"
                                :before-upload="BeforeAvatarUpload"
                                :auto-upload="false"
                                :on-change="OnFileChange"
                                :data="studentinfodata"
                                :headers="HeaderObj"
                                :http-request="HttpPost">
                                <el-avatar shape="square"
                                        :size="100"
                                        fit="cover"
                                        :src="PreviewImage">
                                </el-avatar>
                                <p>点击修改头像</p>
                            </el-upload>
                        </el-col>
                    </el-row>
                    <div class="avatarb_div">
                        <el-button type="primary" @click="submitUpload" :disabled="Loading">上传</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="修改基本信息">
                <el-form class="form_div" ref="EditStudentInfoDataRef" :model="EditStudentInfoDataForm" :rules="EditStudentInfoDataRules" label-width="100px" v-loading="Loading">
                    <el-form-item  label="名称" prop="Name">
                        <el-input v-model="EditStudentInfoDataForm.Name" placeholder="请输入学生名称" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item  label="性别" prop="Gender">
                        <el-radio-group v-model="EditStudentInfoDataForm.Gender">
                            <el-radio label="男"></el-radio>
                            <el-radio label="女"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="年龄" prop="Age">
                        <el-input-number v-model="EditStudentInfoDataForm.Age" :precision="0" :step="1" :min="2" :max="18"></el-input-number>
                    </el-form-item>
                    <el-form-item  label="生日" prop="Birthday">
                        <el-date-picker type="date" 
                            placeholder="选择日期"  
                            format="yyyy 年 MM 月 dd 日" 
                            value-format="yyyy-MM-dd" 
                            v-model="EditStudentInfoDataForm.Birthday">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item  label="就读学校" prop="CurrentSchool">
                        <el-input v-model="EditStudentInfoDataForm.CurrentSchool" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="学校" prop="SchoolID">
                        <el-select v-model="EditStudentInfoDataForm.SchoolID" placeholder="请选择学校" @change="HandleSchoolChange"  value-key="id">
                            <el-option
                            v-for="item in SchoolList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                                <span>{{ item.name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="but_div">
                    <el-button @click="EditStudentInfoDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="EditStudentInfo">确定</el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog title="选择头像" append-to-body @opened="OnDialogOpend" :visible.sync="DialogVisible">
            <div class="preview-image-warp">
                <img class="preview-image"
                    :src="PreviewImage"
                    ref="PreviewImageref">
            </div>
            <span slot="footer"
                    class="dialog-footer">
                <el-button @click="DialogVisible = false">取消</el-button>
                <el-button type="primary" @click="OnUpdataPhoto">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
import { GetSchools } from '@/assets/js/api/schoolapi'
import { PutStudentInfo } from '@/assets/js/api/studentapi'
import { StudentAvatarUpload } from '@/assets/js/systemvariable.js' 
import axios from 'axios';
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'

export default {
    components:{
       
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        studentinfodata:Object,
    },
    computed:{
        EditStudentInfoDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            ActiveName:'',
            SchoolList:[],
            EditStudentInfoDataForm:{
                ID:"",
                GuardianID:0,
                Name:"",
                Gender:"男",
                Age:0,
                Birthday:"",
                CurrentSchool:"",
                School:"",
                SchoolID:0,
                Membership:0,
            },
            EditStudentInfoDataRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                Age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' }
                ],
                SchoolID: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ],
            },
            Loading:false,
            cropper:null,
            PreviewImage:this.studentinfodata.avatarUrl,
            DialogVisible:false,
            HeaderObj:{
                Authorization:"Bearer " + this.$cookies.get("SRJWTTOKEN")
            },
            StudentAvatarUploadUrl:StudentAvatarUpload,
            CropUploadFile:'',
            CanUpLoad:false,
        }
    },
    watch:{
        EditStudentInfoDialogVisible:function (newVisible) {
            if(newVisible){
                this.Schools()
                this.ActiveName ='0'
                this.CanUpLoad = false
                if(this.$refs.EditStudentInfoDataRef)
                {
                    this.$refs.EditStudentInfoDataRef.resetFields()
                }
                this.EditStudentInfoDataForm.ID = this.studentinfodata.id
                this.EditStudentInfoDataForm.GuardianID = this.studentinfodata.guardianID
                this.EditStudentInfoDataForm.Name = this.studentinfodata.name
                this.EditStudentInfoDataForm.Gender = this.studentinfodata.gender
                this.EditStudentInfoDataForm.Age = this.studentinfodata.age
                this.EditStudentInfoDataForm.Birthday = this.studentinfodata.birthday
                this.EditStudentInfoDataForm.CurrentSchool = this.studentinfodata.currentSchool
                this.EditStudentInfoDataForm.SchoolID = this.studentinfodata.schoolID
                this.EditStudentInfoDataForm.School = this.studentinfodata.school
                this.EditStudentInfoDataForm.Membership = this.studentinfodata.membership
                this.PreviewImage = this.studentinfodata.avatarUrl
                
            }
        }
    },
    methods:{
        HttpPost(request){
            //固定上传头像的大小 640*640
            this.cropper.getCroppedCanvas({ width:640, height:640}).toBlob(blob => {
                const { action, data, file} = request;
                var formData = new FormData()
                for (let key in data) {
                    formData.append(key, data[key]);
                }
                formData.append(file.name ,blob, file.name)
                this.Loading = true
                axios({
                    headers: {
                    contentType: 'multipart/form-data' // 须要指定上传的方式
                    },
                    url: action,
                    method: 'post',
                    data: formData,
                    timeout: 200000000 // 防止文件过大超时
                }).then(({ data: resp }) => {
                    const { code, data, message } = resp || {};
                    if (code === 1) {
                        this.$message.success('头像上传成功');
                        this.$emit("ChangeAvatarUploadImgs", data)
                    }
                    else{
                        this.$message.error(message);
                    }
                }).catch(err => {
                    this.$message.error("上传头像失败");
                    console.log(err);
                });
                this.Loading = false
                this.CanUpLoad = true
            })
            // this.$refs.UploadRef.clearFiles();
        },
        submitUpload(){
            if(this.CanUpLoad == false)
            {
               return this.$message.warning("没有需要上传的头像");
            }
            if(this.$refs.UploadImageref)
            {
                this.$refs.UploadImageref.submit();
            }
        },
        BeforeAvatarUpload(file){
            var fileName = file.name;
            var fileType = fileName.substring(fileName.lastIndexOf('.'));
            if(fileType != '.jpg' && fileType != '.png' &&fileType != '.jpeg' &&fileType != '.bmp' ){
                this.$message.error("只能上传jpg/png/bmp/jpeg文件");
                return false;
            }
        },
        HandleAvatarSuccess(response){
            if(response.code == 0)
            {
                this.$message.error(response.message);
            }
            if(response.code == 1){
                this.$emit("ChangeAvatarUploadImgs", response.data)
            }
            this.$refs.UploadRef.clearFiles();
        },
        OnFileChange(file, fileList){
            //限制上传一个
            fileList.length = 0;
            fileList.push(file);  
            var blobData = window.URL.createObjectURL(file.raw)
            this.PreviewImage = blobData
            this.DialogVisible = true
            
            this.CanUpLoad = true
        },
        OnDialogOpend() {
            if (this.cropper) {
                this.cropper.replace(this.PreviewImage)
                return
            }
            // 初始化裁切器
            const image = this.$refs.PreviewImageref
            this.cropper = new Cropper(image, {
                aspectRatio: 1, // 初始化剪切选择框大小
                viewMode: 1 // 不能把剪切框移出图片本身
            })
        },
        OnUpdataPhoto () {
            this.cropper.getCroppedCanvas().toBlob((file) => {
                const imgURL = window.URL.createObjectURL(file)
                this.PreviewImage = imgURL
            })
            this.DialogVisible = false
        },
        async Schools(){
            this.Loading = true
            var ret = await GetSchools()
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.SchoolList = ret.data
            }
            else
            {
               return this.$message.error(ret.message);
            }
        },
        HandleSchoolChange(val){
            this.EditStudentInfoDataForm.SchoolID = val.id
            this.EditStudentInfoDataForm.School = val.name
        },
        async EditStudentInfo(){
            if(this.EditStudentInfoDataForm.Name === this.studentinfodata.name && 
            this.EditStudentInfoDataForm.Gender === this.studentinfodata.gender && 
            this.EditStudentInfoDataForm.Age === this.studentinfodata.age &&
            this.EditStudentInfoDataForm.Birthday === this.studentinfodata.birthday && 
            this.EditStudentInfoDataForm.CurrentSchool === this.studentinfodata.currentSchool && 
            this.EditStudentInfoDataForm.SchoolID === this.studentinfodata.schoolID &&
            this.EditStudentInfoDataForm.Membership === this.studentinfodata.membership ){
                return this.$message.warning('数据没有修改，不需要提交');
            }
            this.$refs.EditStudentInfoDataRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.Loading = true
            var ret = await PutStudentInfo(this.EditStudentInfoDataForm)
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('修改成功');
            }
            else
            {
               return this.$message.error(ret.message);
            }
            this.$emit("ChangeEditStudentInfo", this.EditStudentInfoDataForm)
            this.CloseDialog()
        },  
        CloseDialog(){
            this.EditStudentInfoDialogVisible = false
            this.CanUpLoad = false
        }
    }
}
</script>

<style scoped lang="less">
@import url("@/assets/less/projectless/dialog/student/editstudent.less");
.preview-image-warp {
  .preview-image {
    display: block;
    max-width: 100%;
    height: 300px;
  }
}

.but_div{
    text-align: right;
    ::v-deep .el-button--primary.is-active,::v-deep .el-button--primary:active {
            background-color: var(--color-theme-active);
            border-color:  var(--color-theme-active);
            color: var(--color-background);
        }
        ::v-deep .el-button--primary:focus,::v-deep .el-button--primary:hover {
            background-color:  var(--color-theme-active);
            border-color:  var(--color-theme-active);
            color: var(--color-background);
        }
        ::v-deep .el-button--primary {
            background-color: var(--color-theme-font);
            border-color: var(--color-theme-font);
            color: var(--color-background);
        }

}

</style>