<template>
    <div>
        <el-row class="row_div" >
            <el-card style="margin: 8px">
                <el-descriptions class="margin-top" title="监护人其他信息" :column="2" size="medium" border>
                    <template slot="extra">
                        <el-tooltip effect="dark" content="编辑该监护人其他信息" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" @click="ShowEditMemberElseDialog"></el-button>
                        </el-tooltip>
                    </template>
                      <el-descriptions-item label="来源">
                        <el-tag>{{MemberInfoData.sealType}}</el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="市场">
                        {{MemberInfoData.provider}}
                    </el-descriptions-item>
                    <el-descriptions-item label="课程顾问">
                        {{MemberInfoData.consultant}}
                    </el-descriptions-item>
                    <el-descriptions-item label="状态">
                        {{MemberInfoData.state}}
                    </el-descriptions-item>
                    <el-descriptions-item label="评级">
                        <el-rate v-model="MemberInfoData.scoring" disabled></el-rate>
                    </el-descriptions-item>
                      <el-descriptions-item label="创建时间">
                        {{MemberInfoData.createTime}}
                    </el-descriptions-item>
                </el-descriptions>
            </el-card>
        </el-row>
        <EditMemberElseDialog :visible.sync="EditMemberElseInfoDialogVisible" :memberinfodata="this.MemberInfoData"
        v-on:ChangedMemberElseInfo="ParentChangedMemberElseInfoo($event)">
        </EditMemberElseDialog>
    </div>
</template>

<script>
import EditMemberElseDialog from '../Dialog/editmemberelsedialog.vue';
export default {
    components:{
        EditMemberElseDialog
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        memberinfodata:Object,
        requestmembertag:{
            type: Boolean,
            default:false
        }
    },
    computed:{
        MemberElseInfoVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            MemberElseInfoData:{},
            EditMemberElseInfoDialogVisible:false,
            RequestMemberTag:this.requestmembertag,
            MemberInfoData:this.memberinfodata,
            
        }
    },
    watch:{
       MemberElseInfoVisible:function (newVisible) {
            if(newVisible){
                this.MemberInfoData = this.memberinfodata
            }
        }
    },
    created(){ 
    },
    methods:{ 
        ShowEditMemberElseDialog(){
            this.EditMemberElseInfoDialogVisible = true
        },
        ParentChangedMemberElseInfoo(memberelseinfo){
            this.MemberInfoData.id = memberelseinfo.id
            this.MemberInfoData.consultantID = memberelseinfo.ConsultantID
            this.MemberInfoData.consultant = memberelseinfo.Consultant
            this.MemberInfoData.providerID = memberelseinfo.ProviderID
            this.MemberInfoData.provider = memberelseinfo.Provider
            this.MemberInfoData.sealTypeID = memberelseinfo.SealTypeID
            this.MemberInfoData.sealType = memberelseinfo.SealType
            this.MemberInfoData.state = memberelseinfo.State
            this.MemberInfoData.createTime = memberelseinfo.CreateTime
            this.MemberInfoData.scoring = memberelseinfo.Scoring
            this.$emit("ChangedMemberElseInfo",this.MemberInfoData)
        }
    }
}
</script>

<style scoped lang="less">
    .row_div{
       margin-top: 12px
    }

</style>
