<template>
    <div class="list-div">
        <el-card class="el-card-mainview">
            <el-row :gutter="30">
                <el-col :span="4">
                    <el-input placeholder="请输入用户名" v-model="QueryInfo.Name" clearable @clear="QueryMemberList"></el-input>
                </el-col>
                <el-col :span="4">
                    <el-input placeholder="请输入联系电话" v-model="QueryInfo.Phone" clearable @clear="QueryMemberList"></el-input>
                </el-col>
                <el-col :span="1">
                    <el-button type="primary" @click="QueryMemberList" :disabled="Loading">查询</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="AddMemberPage" :disabled="Loading">新建名单</el-button> 
                </el-col>
            </el-row>
            <el-table :data="MemberList" @row-click="HandleClick" v-loading="Loading" height="74vh">
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="联系人" width="200">
                    <template slot-scope="scope">
                        <el-row>
                            <el-col :span="12">
                                <el-row>
                                    <el-link type="success" :underline="false">{{scope.row.phone}}</el-link>
                                </el-row>
                                <span>{{scope.row.name}}</span>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="学员">
                    <template slot-scope="scope">
                        <el-row v-for="item1 in scope.row.students" 
                        :key="item1.id">
                            <span>{{item1.name}}</span>
                            <i v-if="item1.gender == '女'" style="margin: 8px" class="el-icon-female"/>
                            <i v-if="item1.gender == '男'" style="margin: 8px" class="el-icon-male"/>
                            <span>{{item1.age}}岁</span>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="来源" prop="sealType"></el-table-column>
                <el-table-column label="顾问" prop="consultant"></el-table-column>
                <el-table-column label="状态" prop="state"></el-table-column>
                <el-table-column label="评级" >
                    <template slot-scope="scope">
                        <el-rate v-model="scope.row.scoring" disabled></el-rate>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="160" sortable></el-table-column>
                <el-table-column label="操作" prop="option" width="300">
                    <template> 
                        <el-tooltip effect="dark" content="删除名单信息" placement="top" :enterable="false">
                            <el-button disabled type="danger" icon="el-icon-delete"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <div slot="empty" class="empty_div">
                    <el-empty description="暂无数据！" />
                </div>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="spreadCount"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="MemberTotal">
            </el-pagination>
        </el-card>
        <ModifyMemberDialog :visible.sync="ModifyMemberDialogVisible" :memberinfodata="MemberInfoData" 
        v-on:ChangedMemberInfo="GetMemberList" 
        v-on:ChangedMemberElseInfo="GetMemberList"
        v-on:ChangeEditStudentInfo="GetMemberList"
        v-on:ChangeAddStudentInfo="GetMemberList"
        v-on:ChangeDeleteStudentInfo="GetMemberList">
        </ModifyMemberDialog>

        <AddMemberDialog :visible.sync="AddMemberDialogVisible" 
        v-on:AddMember="GetMemberList"></AddMemberDialog>
    </div>
</template>

<script>
import ModifyMemberDialog from './modifymemberdialog.vue';
import AddMemberDialog from './Dialog/addmemberdialog.vue';
import { GetMemberQuery } from '@/assets/js/api/memberapi';
import { SpreadCount } from '@/assets/js/systemvariable';
export default {
    components:{
        ModifyMemberDialog,
        AddMemberDialog,
    },
    data() {
        return{
            spreadCount:SpreadCount,
            QueryInfo:{
                Name:'',
                Phone:'',
                Start:0,
                Count:SpreadCount[0]
            },
            MemberList:[],
            MemberTotal:0,
            ModifyMemberDialogVisible:false,
            MemberInfoData:{},
            Loading:false,
            AddMemberDialogVisible:false,
        }
    },
    created(){
        this.GetMemberList()
    },
    methods:{
        QueryMemberList(){
            this.QueryInfo.Start = 0
            this.QueryInfo.Count = this.spreadCount[0]
            this.GetMemberList()
        },
        async GetMemberList(){
            this.Loading = true
            var ret = await GetMemberQuery(this.QueryInfo)
            this.Loading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code !== 1){
                return this.$message.error(ret.message);
            }
            this.MemberList = ret.data.members
            this.MemberTotal = ret.data.total
           
        },
        AddMemberPage(){
            this.AddMemberDialogVisible = true
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.GetMemberList()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.GetMemberList()
        },
        HandleClick(row){
            this.MemberInfoData = row
            this.ModifyMemberDialogVisible = true
        }
    }
}
</script>