<template>
    <div>
        <el-row class="row_div" >
            <el-descriptions :column="columncount" title="监护人信息" border v-loading="mLoading">
                <template slot="extra">
                    <el-tooltip effect="dark" content="编辑监护人信息" placement="top" :enterable="false">
                        <el-button v-if="canedit" type="primary" icon="el-icon-edit" @click="EditMemberInfoDialogVisible = true"></el-button>
                    </el-tooltip>
                </template>
                <el-descriptions-item label="姓名">{{GuraDianInfo.name}}</el-descriptions-item>
                <el-descriptions-item label="性别">
                    <el-tag >{{GuraDianInfo.gender}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="电话">{{GuraDianInfo.phone}}</el-descriptions-item>
                <el-descriptions-item label="邮箱">{{GuraDianInfo.email}}</el-descriptions-item>
                <el-descriptions-item label="微信">{{GuraDianInfo.weChat}}</el-descriptions-item>
                <el-descriptions-item label="关系">
                    <el-tag type="success" >{{GuraDianInfo.relationship}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="地址">
                    {{GuraDianInfo.province}}-{{GuraDianInfo.city}}-{{GuraDianInfo.address}}
                </el-descriptions-item>
            </el-descriptions>
        </el-row>
       
        <EditMemberInfoDialog :visible.sync="EditMemberInfoDialogVisible" :memberinfodata="this.GuraDianInfo"
        v-on:ChangedEditMemberInfo="ParentChangedEditMemberInfo($event)">
        </EditMemberInfoDialog>
    </div>
</template>

<script>
import { GetMemberByID } from '@/assets/js/api/memberapi'
import EditMemberInfoDialog from '../Dialog/editmemberinfodialog.vue';
export default {
    components:{
        EditMemberInfoDialog
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        guradianinfo:Object,
        requestmembertag:{
            type: Boolean,
            default:false
        },
        canedit:{
            type: Boolean,
            default:true
        },
        columncount:{
            type: Number,
            default:2
        }
    },
    computed:{
        MemberInfoVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            GuraDianInfo:{},
            EditMemberInfoDialogVisible:false,
            RequestMemberTag:this.requestmembertag,
            mLoading:false,
            
        }
    },
    watch:{
       MemberInfoVisible:function (newVisible) {
            if(newVisible){
                this.RequestMemberTag = this.requestmembertag
                if(this.RequestMemberTag) this.GetMemberInfo()
                else this.GuraDianInfo = this.guradianinfo
            }
        }
    },
    created(){ 
      this.GetMemberInfo()
    },
    methods:{ 
        async GetMemberInfo(){
            this.mLoading = true
            var ret = await GetMemberByID(this.guradianinfo.id)
            this.mLoading = false
            if(ret == null) return this.$message.error('无法请求到服务器，请联系管理员');
            if(ret.code == 1) this.GuraDianInfo = ret.data
            else this.$message.error(ret.message)
        },
        ParentChangedEditMemberInfo(memberinfo){
            this.GuraDianInfo = memberinfo
            this.$emit("ChangeMemberInfo", this.GuraDianInfo)
        }
    }
}
</script>

<style scoped lang="less">
    .row_div{
       margin-top: 12px
    }

</style>
