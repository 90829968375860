<template>
    <el-dialog v-alterELDialogMarginTop="{marginTop:'10vh'}"  title="名单详情" :visible.sync="ModifyMemberDialogVisible" :close-on-click-modal="false"
     @click="ModifyMemberDialogVisible = false"  width="65%">
        <div v-loading="Loading">
            <MemberInfoComponent :columncount="4" :visible.sync="this.ModifyMemberDialogVisible" :guradianinfo="MemberInfoData" :requestmembertag="false"
            v-on:ChangeMemberInfo="ParentChangeMemberInfo($event)">
            </MemberInfoComponent>
            <el-tabs tab-position="left" class="tab_div" v-model="ActiveName">
                <el-tab-pane label="监护人其他信息">
                    <el-col :span="24" type="flex">
                        <MemberElseInfoComponent :visible.sync="this.ModifyMemberDialogVisible" :memberinfodata="MemberInfoData"
                        v-on:ChangedMemberElseInfo="ParentChangedMemberElseInfo($event)">
                        </MemberElseInfoComponent>
                    </el-col>
                </el-tab-pane>
                <el-tab-pane label="学生信息">
                    <el-row>
                        <el-button size="medium" type="text" icon="el-icon-plus" @click="ShowAddStudentDualog">追加学生</el-button>
                    </el-row>
                    <el-row>
                        <template v-for="it in MemberInfoData.students">
                            <div :key="it.id" style="margin: 8px">
                                <StudentInfoComponent :visible="true" :studentinfodata="it" :requeststudenttag="false" :candelete="true"
                                v-on:ChangeDeleteStudentInfo="ParentChangeDeleteStudentInfo($event)"
                                v-on:ChangeEditStudentInfo="ParentChangeEditStudentInfo">
                                </StudentInfoComponent>
                            </div>
                        </template>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </div>

        <AppendStudentDialog :visible.sync="AddStudentDialogVisible" :memberinfodata="this.MemberInfoData"
        v-on:ChangeAppendStudentInfo="ParentChangeAppendStudentInfo($event)">
        </AppendStudentDialog>
        
    </el-dialog>
</template>

<script>
import MemberInfoComponent from '../StudentManager/Component/memberinfo.vue';
import StudentInfoComponent from '../StudentManager/Component/studentinfo.vue';
import AppendStudentDialog from '../StudentManager/Dialog/appendstudentdialog.vue';
import MemberElseInfoComponent from './Component/memberelseinfo.vue';
export default {
    components:{
        MemberInfoComponent,
        StudentInfoComponent,
        AppendStudentDialog,
        MemberElseInfoComponent,
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        memberinfodata:Object
    },
    computed:{
        ModifyMemberDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            ActiveName:'',
            AddStudentDialogVisible:false,
            Loading:false,
            MemberInfoData:{}
        }
    },
    watch:{
        ModifyMemberDialogVisible:function (newVisible) {
            if(newVisible){
                this.ActiveName = '0'
                this.MemberInfoData = this.memberinfodata
            }
        }
    },
    methods:{
        ParentChangeMemberInfo(data){
            this.$emit("ChangedMemberInfo",data)
        },
        async ShowAddStudentDualog(){
            this.AddStudentDialogVisible = true
        },
        ParentChangeDeleteStudentInfo(student){
            var indexstudent = 0
            for (var i = 0; i < this.MemberInfoData.students.length; i++) {
                if (this.MemberInfoData.students[i]["id"] == student.id) {
                    indexstudent = i
                    this.MemberInfoData.students.splice(indexstudent, 1)
                    break
                }
            }
            this.$emit("ChangeDeleteStudentInfo", student)
        },
        ParentChangeAppendStudentInfo(student){
            var obj = {
                id:student.ID,
                age:student.Age,
                birthday:student.Birthday,
                currentSchool:student.CurrentSchool,
                gender:student.Gender,
                guardianID:student.GuardianID,
                name:student.Name,
                school:student.School,
                schoolID:student.SchoolID,
                membership:student.Membership,
            }
            this.MemberInfoData.students.push(obj)
            this.$emit("ChangeAddStudentInfo", student)
        },
        ParentChangedMemberElseInfo(memberelseinfo){
            this.$emit("ChangedMemberElseInfo",memberelseinfo)
        },
        ParentChangeEditStudentInfo(){
            this.$emit("ChangeEditStudentInfo")
        }
    }
}
</script>


<style scoped lang="less">
    ::v-deep .el-tabs__item {
        background: #fff !important;
    }
    .tab_div{
        margin-top:20px ;
        height: 50vh;
        overflow: auto;
    }
</style>