import axios from 'axios';

//修改学生所属班级
export async function PutStudentClass(classinfo){
    var res = await axios.put('Student/v1/StudentClass', classinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //根据班级ID获取班级学生
  export async function GetStudentQueryByClassID(queryinfo){
    var res = await axios.get('Student/v1/StudentQueryByClassID', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  
  //获取除此ClassID外的学生列表
  export async function GetStudentsExceptClassID(queryinfo){
    var res = await axios.get('Student/v1/StudentQueryExceptClassID', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //根据班级和课表ID获取今日待签到的学生
  export async function GetStudentToSignupCourseByClass(queryinfo){
    var res = await axios.get('Student/v1/QueryStudentToSignupCourseByClass', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

  //根据学生ID获取学生课时
export async function GetStudentCourseNumberByID(id){
    var res = await axios.get('Student/v1/StudentCourseNumberByID',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  
//上传学生头像
export async function PostStudentAvatar(formData){
// var res = await axios.post('Student/v1/Upload',{ data: file})
// .then(function (response) {
//   return response.data;
// })
// .catch(function (error) {
//   console.log(error);
//   return null;
// });
// return res;
    var res = await axios.post({ headers: { contentType: 'multipart/form-data' }, method: 'post', data: formData, timeout: 200000000 }).
    then(function (response) {
        return response.data;
    }).catch(err => {
        console.log(err);
    });
    return res;
}

//通过老师获取学生列表
export async function GetQueryStudentsListByTeacher(queryinfo){
    queryinfo.ClassIDs = queryinfo.ClassIDs + ''
    var res = await axios.get('Student/v1/StudentsQueryByTeacher', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
}

//根据ID获取学生信息
export async function GetStudentByID(id){
    var res = await axios.get('Student/v1/StudentByID', { params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

//通过课程ID获取操作日志
export async function GetOperationLogByCourseNumberID(id){
    var res = await axios.get('Student/v1/OperationLogByCourseNumberID', { params: { CourseNumberID:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
  //获取今日签到学生
  export async function GetStudentWaitingSign(){
    var res = await axios.get('Student/v1/StudentWaitingSign')
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

//通过课程ID获取学生列表
export async function GetStudentsBySchedule(id){
    var res = await axios.get('Student/v1/StudentsBySchedule', { params: { scheduleid:id}})
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }


//获取学生课时
export async function GetStudentCourseNumberByStudentID(queryinfo){
  var res = await axios.get('Student/v1/CourseNumber', {params : queryinfo})
  .then(function(response){
    return response.data.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//修改学生课时信息
export async function PutStudenCoursetNumber(coursenumberinfo){
  var res = await axios.put('Student/v1/CourseNumber', coursenumberinfo)
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//通过会员标志学生列表
export async function GetQueryStudentsListByMembership(queryinfo){
  var res = await axios.get('Student/v1/StudentsQueryByMembership', {params: queryinfo})
  .then(function (response) {
    return response.data.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}
  
  //修改学生会员标志
  export async function PutStudentMembership(studentinfo){
    var res = await axios.put('Student/v1/StudentMembership', studentinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }

//删除名单里的学生信息
export async function DeleteStudentInfo(id){
    var res = await axios.delete('Student/v1/Student',{ params: { id:id}})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }
  
//通过排课标志学生列表
export async function GetQueryStudentsList(queryinfo){
  //数组切换为string
  queryinfo.ClassIDs = queryinfo.ClassIDs + ''
  var res = await axios.get('Student/v1/StudentsQuery', {params: queryinfo},
  {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}



//修改名单的学生信息
export async function PutStudentInfo(studentinfo){
  var res = await axios.put('Student/v1/Student', studentinfo)
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//提交名单里的学生信息
export async function PostStudentInfo(studentinfo){
  var res = await axios.post('Student/v1/Student', studentinfo)
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//根据课时ID获取学生课时
export async function GetStudentCourseNumberByCourseNumberID(id){
  var res = await axios.get('Student/v1/CourseNumberByID', {params : {ID : id}})
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//修改学生的课时状态
export async function PutStudentCourseNumberState(datainfo){
  var res = await axios.put('Student/v1/StudentCourseNumberState', datainfo)
  .then(function(response){
      return response.data;
  })
  .catch(function (error){
      console.log(error);
      return null;
  });
  return res;
}


//根据学生ID获取学生课时
export async function GetStudentCourseNumberByStudentID_2(id){
  var res = await axios.get('Student/v1/StudentCourseNumberByStudentID', {params : {id : id}})
  .then(function(response){
    return response.data.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}


//获取学生日
export async function GetStudentBirthday(){
  var res = await axios.get('Student/v1/StudentBirthday')
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}

//根据学生ID获取学生未来的课表
export async function GetStudentCoursePlanByID(id){
  var res = await axios.get('Student/v1/StudentCoursePlanByID', {params : {studentid : id}})
  .then(function(response){
    return response.data;
  })
  .catch(function (error){
    console.log(error);
    return null;
  });
  return res;
}
