<template>
    <el-dialog :append-to-body="true" class="dialog_div" title="追加学生" :visible.sync="AddStudentDialogVisible" 
    :close-on-click-modal="false" width="30%" @close="CloseDialog">
        <el-form class="form_div" ref="StudentInfoDataRef" :model="StudentInfoDataForm" :rules="StudentInfoDataFormRules" 
        label-width="80px" v-loading="Loading">
            <el-form-item  label="名称" prop="Name">
                <el-input v-model="StudentInfoDataForm.Name" placeholder="请输入学生名称" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="Gender">
                <el-radio-group v-model="StudentInfoDataForm.Gender">
                    <el-radio label="男" border></el-radio>
                    <el-radio label="女" border></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄" prop="Age">
                <el-input-number v-model="StudentInfoDataForm.Age" :precision="0" :step="1" :min="2" :max="18"></el-input-number>
            </el-form-item>
            <el-form-item  label="生日" prop="Birthday">
                <el-date-picker type="date" 
                    placeholder="选择日期"  
                    format="yyyy 年 MM 月 dd 日" 
                    value-format="yyyy-MM-dd" 
                    v-model="StudentInfoDataForm.Birthday">
                </el-date-picker>
            </el-form-item>
            <el-form-item  label="就读学校" prop="CurrentSchool">
                <el-input v-model="StudentInfoDataForm.CurrentSchool" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="学校" prop="SchoolID">
                <el-select v-model="StudentInfoDataForm.SchoolID" value-key="id" placeholder="请选择学校">
                    <el-option
                    v-for="item in SchoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                        <span>{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog()" :disabled="Loading">取消</el-button>
            <el-button type="primary" @click="AddStudentInfo" :disabled="Loading">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { GetSchools } from '@/assets/js/api/schoolapi' 
import { PostStudentInfo } from '@/assets/js/api/studentapi' 
export default {
    components:{
      
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        memberinfodata:Object,
    },
    computed:{
        AddStudentDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            StudentInfoDataForm:{
                ID:"",
                GuardianID:0,
                Name:"",
                Gender:"男",
                Age:0,
                Birthday:"",
                CurrentSchool:"",
                School:"",
                SchoolID:0,
                Membership:0
            },
            StudentInfoDataFormRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                Age: [
                    { required: true, message: '请输入年龄', trigger: 'blur' }
                ],
                SchoolID: [
                    { required: true, message: '请选择学校', trigger: 'change' }
                ],
                Membership: [
                    { required: true, message: '请选择会员标志', trigger: 'change' }
                ],
            },
            Loading:false,
            SchoolList:[],
        }
    },
    watch:{
        AddStudentDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.StudentInfoDataRef)
                {
                    this.$refs.StudentInfoDataRef.resetFields()
                }
                this.StudentInfoDataForm.GuardianID = this.memberinfodata.id
                this.StudentInfoDataForm.ID = 0
                this.StudentInfoDataForm.Name = ''
                this.StudentInfoDataForm.Gender = '男'
                this.StudentInfoDataForm.Age = 2
                this.StudentInfoDataForm.Birthday = ''
                this.StudentInfoDataForm.CurrentSchool = ''
                this.StudentInfoDataForm.SchoolID = this.SchoolList[0].id
                this.StudentInfoDataForm.Membership = 0

            }
        }
    },
    created(){
        this.Schools()
    },
    methods:{
        async Schools(){
            this.Loading = true
            var ret = await GetSchools()
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.SchoolList = ret.data
            }
            else
            {
               return this.$message.error(ret.message);
            }
        },
        async AddStudentInfo(){
            this.$refs.StudentInfoDataRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.Loading = true
            var ret = await PostStudentInfo(this.StudentInfoDataForm)
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('追加成功');
            }
            else
            {
                return this.$message.error(ret.message);
            }
            this.StudentInfoDataForm.ID = ret.data.id
            this.$emit("ChangeAppendStudentInfo", this.StudentInfoDataForm)
            this.CloseDialog()
        },
        CloseDialog(){
            this.AddStudentDialogVisible = false
        }
    }
}
</script>

<style scoped lang="less">
    .dialog_div{
        text-align: center
    }
    .form_div{
        text-align: left;
    }
</style>