<template>
    <el-dialog width="40%" class="dialog_div" title="新建名单" 
    :append-to-body="true" :visible.sync="AddMemberDialogVisible" :close-on-click-modal="false" @close="CloseDialog">
        <el-form :model="MemberInfo" :rules="MemberInfoRules" ref="MemberInfoRef" label-width="80px" v-loading="Loading">
            <el-form-item label="姓名" prop="Name">
                <el-input class="dialog_form_input" placeholder="家长姓名" v-model="MemberInfo.Name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="Gender">
                <el-radio-group class="dialog_form_radio" v-model="MemberInfo.Gender">
                    <el-radio label="男" border></el-radio>
                    <el-radio label="女" border></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="电话" prop="Phone">
                <el-input class="dialog_form_input" placeholder="联系电话" v-model="MemberInfo.Phone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="Email">
                <el-input class="dialog_form_input" placeholder="邮箱" v-model="MemberInfo.Email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="微信" prop="WeChat">
                <el-input class="dialog_form_input" placeholder="微信号" v-model="MemberInfo.WeChat" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="关系" prop="RelationShip">
                <el-select class="dialog_form_select" v-model="MemberInfo.RelationShip">
                    <el-option
                    v-for="item in RelationShip"
                    :key="item"
                    :label="item"
                    :value="item">
                        <span>{{ item }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="城市">
                <el-cascader class="dialog_form_ascader" :options="CityOptions" v-model="SelectedCityOptions" @change="SelectedCityHandleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="地址" prop="Address">
                <el-input class="dialog_form_input" placeholder="家庭地址" v-model="MemberInfo.Address" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="CloseDialog" :disabled="Loading">取消</el-button>
            <el-button type="primary" @click="AddMember"  :disabled="Loading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { provinceAndCityData, TextToCode, CodeToText } from 'element-china-area-data'
import { RELATIONSHIP } from '@/assets/js/systemvariable.js' 
import { PostMember } from '@/assets/js/api/memberapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
    },
    computed:{
        AddMemberDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            Loading:false,
            MemberInfo:{
                Name:'',
                Gender:"男",
                Phone:'',
                Email:'',
                WeChat:'',
                QQ:'',
                RelationShip:"爸爸",
                Province:"山东省",
                City:"潍坊市",
                Address:'',
            },
            MemberInfoRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                RelationShip: [
                    { required: true, message: '请选择关系', trigger: 'change' }
                ],
                Address: [
                    { required: true, message: '请输入家庭地址', trigger: 'blur' }
                ],
            },
            RelationShip:RELATIONSHIP,
            CityOptions: provinceAndCityData,
            SelectedCityOptions:[],
        }
    },
    watch:{
        AddMemberDialogVisible:function (newVisible) {
            if(newVisible){
                this.GetCitryParams()
                //第一次打开时undefined，直接调用resetFields会报错
                if(this.$refs.MemberInfoRef){
                    this.$refs.MemberInfoRef.resetFields();
                }
            }
        }
    },
    methods:{
        CloseDialog(){
            this.AddMemberDialogVisible = false
        },
        //默认城市
        GetCitryParams(){
            this.SelectedCityOptions = [TextToCode["山东省"].code.toString(),TextToCode["山东省"]["潍坊市"].code.toString()]
        },
        SelectedCityHandleChange(value) {
            this.MemberInfo.Province = CodeToText[value[0]]
            this.MemberInfo.City = CodeToText[value[1]]
        },
        async AddMember(){
            this.$refs.MemberInfoRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            const confirmResult = await this.$confirm('确认添加新的名单 ' + this.MemberInfo.Name + ' ,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消操作')
            }
            this.MemberInfo.QQ = +this.MemberInfo.QQ
            
            this.Loading = true
            var ret = await PostMember(this.MemberInfo)
            this.Loading = false
            if(ret == null){
                if(this.$refs.MemberInfoRef){
                    this.$refs.MemberInfoRef.resetFields();
                }
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('提交成功');
            }
            else
            {
                this.$message.error(ret.message);
            }
            this.AddMemberDialogVisible = false
            this.$emit('AddMember')
        }
    }
}
</script>


<style scoped lang="less">
.dialog_form_input{
    width: 30vw;
    display: flex;
}
.dialog_form_select{
    display:table
}
.dialog_form_radio{
    display: flex;
}
.dialog_form_ascader{
    width: 20vw;
    display: flex;
}
</style>