<template>
    <el-dialog width="30%" class="dialog_div" title="编辑监护人其他信息" 
    :append-to-body="true" :visible.sync="EditMemberElseDialogVisible" :close-on-click-modal="false" @close="CloseDialog">
        <el-form class="form_div" ref="MemberElseInfoDataRef" :model="MemberElseInfoDataForm" :rules="MemberElseInfoDataRules" 
        label-width="100px" v-loading="Loading">
            <el-form-item label="来源" prop="SealType">
                <!-- 使用 value-key="id" 防止选项变蓝色-->
                <el-select clearable v-model="MemberElseInfoDataForm.SealType" placeholder="请选择来源类型" @change="HandleSealTypeChange" value-key="id">
                    <el-option
                    v-for="item in SealTypeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                        <span>{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="市场" prop="ProviderID">
                <el-select clearable v-model="MemberElseInfoDataForm.Provider" placeholder="请选择用户" @change="HandleProviderChange" value-key="id">
                    <el-option
                    v-for="item in EmployeeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                        <span>{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="顾问" prop="ConsultantID">
                <el-select clearable v-model="MemberElseInfoDataForm.Consultant" placeholder="请选择用户" @change="HandleConsultantChange" value-key="id">
                    <el-option
                    v-for="item in EmployeeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                        <span>{{ item.name }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="评级" prop="ConsuScoringltantID">
                <el-rate class="rate_div" v-model="MemberElseInfoDataForm.Scoring" @change="DataChangeTag = true"></el-rate>
            </el-form-item>
            <el-form-item label="创建时间" prop="CreateTime">
                <el-input class="input_div" disabled v-model="MemberElseInfoDataForm.CreateTime" @input="DataChangeTag = true"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="CloseDialog" :disabled="Loading">取消</el-button>
            <el-button type="primary" v-on:click="EditMemberElseInfo" :disabled="Loading">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { PutMemberElseInfoData  } from '../../../assets/js/api/memberapi' 
import { GetAllEmployeeList } from '../../../assets/js/api/userapi' 
import { GetSealTypeList } from '../../../assets/js/api/sealtypeapi'
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        memberinfodata:Object,
    },
    computed:{
        EditMemberElseDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            MemberElseInfoDataForm:{
                id:'',
                ProviderID:'',
                Provider:'',
                ConsultantID:'',
                Consultant:'',
                SealTypeID:'',
                SealType:'',
                State:'',
                CreateTime:'',
                Scoring:0
            },
            MemberElseInfoDataRules:{
                SealType: [
                    { required: false, message: '请选择来源', trigger: 'change' }
                ]
            },
            SealTypeList:[],
            EmployeeList:[],
            DataChangeTag:false,
            Loading:true,
            SlLoading:true,
            EpLoading:true,
        }
    },
    watch:{
        SlLoading: function(newval){
            if(newval == false && this.EpLoading == false){
                this.Loading = false
            }
        },
        EpLoading: function(newval){
            if(newval == false && this.SlLoading == false){
                this.Loading = false
            }
        },
        EditMemberElseDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.MemberElseInfoDataRef){
                    this.$refs.MemberElseInfoDataRef.resetFields();
                }
                this.Loading = true
                this.GetSealType()
                this.GetEmployeeList()
                this.DataChangeTag = false
                this.MemberElseInfoDataForm.id = this.memberinfodata.id
                this.MemberElseInfoDataForm.ConsultantID = this.memberinfodata.consultantID
                this.MemberElseInfoDataForm.Consultant = this.memberinfodata.consultant
                this.MemberElseInfoDataForm.ProviderID = this.memberinfodata.providerID
                this.MemberElseInfoDataForm.Provider = this.memberinfodata.provider
                this.MemberElseInfoDataForm.SealTypeID = this.memberinfodata.sealTypeID
                this.MemberElseInfoDataForm.SealType = this.memberinfodata.sealType
                this.MemberElseInfoDataForm.State = this.memberinfodata.state
                this.MemberElseInfoDataForm.CreateTime = this.memberinfodata.createTime
                this.MemberElseInfoDataForm.Scoring = this.memberinfodata.scoring
            }
        }
    },
    methods:{
        async GetSealType(){
            this.SlLoading = true
            var ret = await GetSealTypeList()
            this.SlLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.SealTypeList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        async GetEmployeeList(){
            this.EpLoading = true
            var ret = await GetAllEmployeeList()
            this.EpLoading = false
            if(ret == null){
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.EmployeeList = ret.data
            }
            else
            {
                this.$message.error(ret.message);
            }
        },
        HandleProviderChange(val){
            this.MemberElseInfoDataForm.ProviderID = val.id
            this.MemberElseInfoDataForm.Provider = val.name
            this.DataChangeTag = true
        },
        HandleConsultantChange(val){
            this.MemberElseInfoDataForm.ConsultantID = val.id
            this.MemberElseInfoDataForm.Consultant = val.name
            this.DataChangeTag = true
        },
        HandleSealTypeChange(val){
            this.MemberElseInfoDataForm.SealTypeID = val.id
            this.MemberElseInfoDataForm.SealType = val.name
            this.DataChangeTag = true
        },
        async EditMemberElseInfo(){
            if(this.DataChangeTag == false){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.Loading = true
            var ret = await PutMemberElseInfoData(this.MemberElseInfoDataForm)
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('提交成功');
            }
            else
            {
                this.$message.error(ret.message);
                return;
            }
            if(this.MemberElseInfoDataForm.ConsultantID && this.MemberElseInfoDataForm.ConsultantID != 0)
            {
                this.MemberElseInfoDataForm.State = "已分配顾问"
            }
            else
            {
                this.MemberElseInfoDataForm.State = "未分配顾问"
            }
            this.$emit("ChangedMemberElseInfo",this.MemberElseInfoDataForm)
            this.CloseDialog()
        },
        CloseDialog(){
            this.EditMemberElseDialogVisible = false
        }
    }
}
</script>

<style scoped lang="less">
    .dialog_div{
        text-align: center
    }
    .form_div{
        text-align: left;
    }
    .input_div{
        width: 220px
    }
    .rate_div{
        display: flex; 
        margin: 10px
    }
</style>