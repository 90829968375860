<template>
    <div>
        <el-row>
            <el-card>
                <el-col style="margin-bottom: 8px" :span="12" type="flex">
                    <div>
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="StudentInfo.avatarUrl"
                            fit="cover"></el-image>
                    </div>
                        <el-tag style="margin: 2px" v-if="StudentInfo.membership == 1" value="会员" type="success">
                            {{"会员"}}
                        </el-tag >
                        <el-tag style="margin: 2px" v-if="StudentInfo.membership == 0" value="非会员" type="danger">
                            {{"非会员"}}
                        </el-tag >
                </el-col>
                <el-col style="margin-bottom: 8px" :span="12">
                    <el-descriptions :column="2" title="基本信息" v-loading="sLoading">
                        <template slot="extra">
                            <el-tooltip effect="dark" content="编辑学生信息" placement="top" :enterable="false">
                                <el-button v-if="canedit" type="primary" icon="el-icon-edit" @click="EditStudentInfoDialogVisible = true"></el-button>
                            </el-tooltip>
                            <el-tooltip effect="dark" content="删除学生信息" placement="top" :enterable="false">
                                <el-button v-if="candelete" type="danger" icon="el-icon-delete" @click="DeleteStudentInfo()"></el-button>
                            </el-tooltip>
                        </template>
                        <el-descriptions-item label="学生姓名"> {{StudentInfo.name}} </el-descriptions-item>
                        <el-descriptions-item label="性别"> {{StudentInfo.gender}} </el-descriptions-item>
                        <el-descriptions-item label="年龄"> {{StudentInfo.age}} </el-descriptions-item>
                        <el-descriptions-item label="生日"> {{StudentInfo.birthday}} </el-descriptions-item>
                        <el-descriptions-item label="就读学校"> {{StudentInfo.currentSchool}} </el-descriptions-item>
                        <el-descriptions-item label="所属校区"> {{StudentInfo.school}} </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-card>
        </el-row>
        <EditStudentInfoDialog :visible.sync="EditStudentInfoDialogVisible" :studentinfodata="this.StudentInfo"
            v-on:ChangeEditStudentInfo="ParentChangeEditStudentInfo($event)"
            v-on:ChangeAvatarUploadImgs="ParentChangeAvatarUploadImgs($event)">
        </EditStudentInfoDialog>
    </div>
</template>

<script>
import { GetStudentByID, DeleteStudentInfo } from '@/assets/js/api/studentapi'
import { StudentAvatarViewUrl, DefaultViewUrl } from '@/assets/js/systemvariable.js' 
import EditStudentInfoDialog from '../Dialog/editstudentinfodialog.vue';
export default {
    components:{
        EditStudentInfoDialog
    },
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        studentinfodata:Object,
        requeststudenttag:{
            type: Boolean,
            default:false
        },
        canedit:{
            type: Boolean,
            default:true
        },
        candelete:{
            type: Boolean,
            default:false
        }
    },
    computed:{
        StudentInfoComponentVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        }
    },
    data(){
        return{
            StudentInfo:this.studentinfodata,
            EditStudentInfoDialogVisible:false,
            sLoading:false,
            RequestStudentTag:this.requeststudenttag,
            ErrorDefaultViewUrl:DefaultViewUrl
        }
    },
    watch:{
        StudentInfoComponentVisible:function (newVisible) {
            if(newVisible){
                this.RequestStudentTag = this.requeststudenttag
                if(this.RequestStudentTag)
                {
                    this.StudentByID()
                }
                else{
                    this.StudentInfo = this.studentinfodata
                }
            }
        }
    },
    created(){
        this.StudentByID()
    },
    methods:{ 
        async DeleteStudentInfo(){
            const confirmResult = await this.$confirm('是否要删除该学生, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).
            catch(err=>err);
            if(confirmResult !== 'confirm'){
                return this.$message.info('已取消删除')
            }
            this.sLoading = true
            var ret = await DeleteStudentInfo(this.studentinfodata.id)
            this.sLoading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('删除成功');
            }
            else
            {
                this.$message.error(ret.message);
                return;
            }
            this.$emit("ChangeDeleteStudentInfo", this.StudentInfo)
        },
        async StudentByID(){
            this.sLoading = true
            var ret = await GetStudentByID(this.studentinfodata.id)
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.StudentInfo = ret.data
                if(this.StudentInfo.avatarUrl === ''){
                    this.StudentInfo.avatarUrl = DefaultViewUrl
                }
                else{
                    this.StudentInfo.avatarUrl = StudentAvatarViewUrl + this.StudentInfo.avatarUrl
                }
                this.sLoading = false
            }
            else
            {
                this.$message.error(ret.message);
                return;
            }
        },
        ParentChangeEditStudentInfo(studentinfo){
            this.StudentInfo.id = studentinfo.ID
            this.StudentInfo.guardianID = studentinfo.GuardianID
            this.StudentInfo.name = studentinfo.Name
            this.StudentInfo.gender = studentinfo.Gender
            this.StudentInfo.age = studentinfo.Age
            this.StudentInfo.birthday = studentinfo.Birthday
            this.StudentInfo.currentSchool = studentinfo.CurrentSchool
            this.StudentInfo.schoolID = 1
            this.StudentInfo.school = studentinfo.School
            this.StudentInfo.membership = studentinfo.Membership
            this.$emit("ChangeEditStudentInfo", this.StudentInfo)
        },
        ParentChangeAvatarUploadImgs(studentinfoavatar){
            this.StudentInfo.avatarUrl = StudentAvatarViewUrl + studentinfoavatar.url
        }
    }
}
</script>
