import axios from 'axios';

//名单获取途径
export async function GetSealTypeList(){
  var res = await axios.get('SealType/v1/SealTypes')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}
//获取所有活动信息
export async function GetAllSealTypeList(){
  var res = await axios.get('SealType/v1/SealTypes')
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//根据ID删除支付方式
export async function DeleteSealType(id){
  var res = await axios.delete('SealType/v1/SealType',{ params: { id:id}})
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return null;
  });
  return res;
}

//查询可用活动
export async function GetSealTypeQueryList(queryinfo){
    var res = await axios.get('SealType/v1/SealTypesQuery', {params: queryinfo})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return null;
    });
    return res;
  }

  //提交添加活动信息
export async function PostSealType(sealtypeinfo){
    var res = await axios.post('SealType/v1/SealType', sealtypeinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }

  //提交修改支付方式信息
export async function PutSealType(sealtypeinfo){
    var res = await axios.put('SealType/v1/ModifySealType', sealtypeinfo)
    .then(function(response){
      return response.data;
    })
    .catch(function (error){
      console.log(error);
      return null;
    });
    return res;
  }
  