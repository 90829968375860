<template>
    <el-dialog class="dialog_div" :append-to-body="true" title="编辑监护人信息" :visible.sync="EditMemberInfoDialogVisible" :close-on-click-modal="false" width="40%">
        <el-form class="form_div" ref="MemberInfoDataRef" :model="MemberInfoDataForm" :rules="MemberInfoDataRules" label-width="100px" v-loading="Loading">
                <el-form-item label="姓名" prop="Name">
                    <el-input v-model="MemberInfoDataForm.Name" @input="DataChangeTag = true"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="Gender">
                   <el-radio-group v-model="MemberInfoDataForm.Gender" @change="DataChangeTag = true">
                        <el-radio label="男" border></el-radio>
                        <el-radio label="女" border></el-radio>
                    </el-radio-group>
                </el-form-item>
               <el-form-item label="电话" prop="Phone">
                    <el-input placeholder="联系电话" v-model="MemberInfoDataForm.Phone" autocomplete="off" @input="DataChangeTag = true"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="Email">
                    <el-input placeholder="邮箱" v-model="MemberInfoDataForm.Email" autocomplete="off" @input="DataChangeTag = true">></el-input>
                </el-form-item>
                <el-form-item label="微信" prop="WeChat">
                    <el-input placeholder="微信号" v-model="MemberInfoDataForm.WeChat" autocomplete="off" @input="DataChangeTag = true">></el-input>
                </el-form-item>
                <el-form-item label="关系" prop="RelationShip">
                    <el-select v-model="MemberInfoDataForm.RelationShip" @change="SelectedRelationShipHandleChange">
                        <el-option
                        v-for="item in RelationShipList"
                        :key="item"
                        :label="item"
                        :value="item">
                            <span>{{ item }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市">
                    <el-col :span="6">
                        <el-cascader :options="Options" v-model="SelectedOptions" @change="SelectedCityHandleChange"></el-cascader>
                    </el-col>
                </el-form-item>
                <el-form-item label="地址" prop="Address">
                    <el-input placeholder="家庭地址" v-model="MemberInfoDataForm.Address" autocomplete="off" @input="DataChangeTag = true"></el-input>
                </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="EditMemberInfoDialogVisible = false" :disabled="Loading">取消</el-button>
            <el-button type="primary" v-on:click="EditMemberInfo" :disabled="Loading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { RELATIONSHIP } from '@/assets/js/systemvariable.js' 
import { provinceAndCityData, CodeToText,TextToCode } from 'element-china-area-data'
import { PutMemberInfoData} from '@/assets/js/api/memberapi' 
export default {
    props:{
        visible:{
            type: Boolean,
            default:false
        },
        memberinfodata:Object,
    },
    computed:{
        EditMemberInfoDialogVisible:{
            get: function() {
                return this.visible
            },
            set: function(val) {
                this.$emit('update:visible', val)
            }
        },
    },
    data(){
        return{
            RelationShipList:RELATIONSHIP,
            SelectedOptions:[],
            Options: provinceAndCityData,
            MemberInfoDataForm:{
                ID:'',
                Name:'',
                Gender:'',
                Phone:'',
                Email:'',
                WeChat:'',
                QQ:'',
                RelationShip:'',
                Province:'',
                City:'',
                Address:'',
            },
            MemberInfoDataRules:{
                Name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                Gender: [
                    { required: true, message: '请选择性别', trigger: 'change' }
                ],
                RelationShip: [
                    { required: true, message: '请选择关系', trigger: 'change' }
                ],
                Address: [
                    { required: true, message: '请输入家庭地址', trigger: 'blur' }
                ]
            },
            Loading:false,
            DataChangeTag:false,
        }
    },
    watch:{
       EditMemberInfoDialogVisible:function (newVisible) {
            if(newVisible){
                if(this.$refs.MemberInfoDataRef){
                    this.$refs.MemberInfoDataRef.resetFields();
                }
                this.DataChangeTag = false
                this.MemberInfoDataForm.ID = this.memberinfodata.id
                this.MemberInfoDataForm.Name = this.memberinfodata.name
                this.MemberInfoDataForm.Gender = this.memberinfodata.gender
                this.MemberInfoDataForm.Phone = this.memberinfodata.phone
                this.MemberInfoDataForm.Email = this.memberinfodata.email
                this.MemberInfoDataForm.WeChat = this.memberinfodata.weChat
                this.MemberInfoDataForm.QQ = this.memberinfodata.qq
                this.MemberInfoDataForm.RelationShip = this.memberinfodata.relationship
                this.MemberInfoDataForm.Province = this.memberinfodata.province
                this.MemberInfoDataForm.City = this.memberinfodata.city
                this.MemberInfoDataForm.Address = this.memberinfodata.address
                this.GetParams()
            }
        }
    },
    methods:{
        GetParams(){
            this.SelectedOptions = [TextToCode[this.MemberInfoDataForm.Province].code.toString(),TextToCode[this.MemberInfoDataForm.Province][this.MemberInfoDataForm.City].code.toString()]
        },
        SelectedCityHandleChange(value) {
            this.MemberInfoDataForm.Province = CodeToText[value[0]]
            this.MemberInfoDataForm.City = CodeToText[value[1]]
            this.DataChangeTag = true
        },
        SelectedRelationShipHandleChange(item){
            this.MemberInfoDataForm.RelationShip = item
            this.DataChangeTag = true
        },
        async EditMemberInfo(){
            if(this.DataChangeTag == false){
                this.$message.warning('数据没有修改，不需要提交');
                return
            }
            this.$refs.MemberInfoDataRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            this.MemberInfoDataForm.QQ = +this.MemberInfoDataForm.QQ
            this.Loading = true
            var ret = await PutMemberInfoData(this.MemberInfoDataForm)
            this.Loading = false
            if(ret == null)
            {
                return this.$message.error('无法请求到服务器，请联系管理员');
            }
            if(ret.code == 1)
            {
                this.$message.success('提交成功');
                this.$emit("ChangedEditMemberInfo",ret.data)
            }
            else
            {
                this.$message.error(ret.message);
                return;
            }
            this.EditMemberInfoDialogVisible = false
        }
    }
}
</script>

<style scoped lang="less">
.dialog_div{
    text-align: center;
}
.form_div{
    text-align: left;
}
</style>